import Swiper from 'swiper'
import gsap from 'gsap'
var pcFlg = true, tabletFlg = false, spFlg = false
if (navigator.userAgent.indexOf('iPhone') !== -1 || navigator.userAgent.indexOf('iPod') !== -1 || navigator.userAgent.indexOf('iPad') !== -1 || (navigator.userAgent.indexOf('Android') !== -1)) {
  pcFlg = false
  if (navigator.userAgent.indexOf('iPhone') !== -1 || navigator.userAgent.indexOf('iPod') !== -1 || (navigator.userAgent.indexOf('Android') !== -1 && navigator.userAgent.indexOf('Mobile') !== -1)) {
    spFlg = true
  } else if (navigator.userAgent.indexOf('iPad') !== -1 || (navigator.userAgent.indexOf('Android') !== -1 && navigator.userAgent.indexOf('Mobile') < 0)) {
    tabletFlg = true
  }
}

$(document).ready(function () {
  /* eslint-disable no-unused-vars */
  /* -- mySwiperは利用しないがno-new rules errorを回避するため。 */
  const mySwiper = new Swiper('.mainvisual .swiper-container', {
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    speed: 500,
    effect: 'fade',
    loop: true
  })
  /* eslint-enable no-unused-vars */

  $(window).scroll(function () {
    $('.js-scrollin').each(function () {
      var elemPos = $(this).offset().top
      var scroll = $(window).scrollTop()
      var windowHeight = $(window).height()
      // eslint-disable-next-line no-undef
      if (pcFlg || tabletFlg) {
        if (scroll > elemPos - windowHeight + 150) {
          $(this).addClass('o-scrollin')
        }
      } else if (spFlg) {
        if (scroll > elemPos - windowHeight + 300) {
          $(this).addClass('o-scrollin')
        }
      }
    })
  })
})

window.onload = function () {
  const thumbList = document.querySelectorAll('.topic_img')
  const ratio = 1
  for (let i = 0; i < thumbList.length; i++) {
    const imgWidth = getComputedStyle(thumbList[i], null)['width']
    const imgHeight = getComputedStyle(thumbList[i], null)['height']
    const imgRatio = (parseFloat(imgWidth) / parseFloat(imgHeight)).toFixed(2)
    if (imgRatio > ratio) {
      thumbList[i].classList.add('o-width-trim')
    } else if (imgRatio < ratio) {
      thumbList[i].classList.add('o-height-trim')
    } else {
      thumbList[i].classList.add('o-no-trim')
    }
  }

  bannerSlide()
  // initMail()
}

function bannerSlide () {
  const slideTargetClass = '.banner-slide'
  const bannerSlideBlock = document.querySelector(slideTargetClass)
  const bannerSlideElm = bannerSlideBlock.querySelector('.swiper-container')
  const slideLength = bannerSlideElm.querySelectorAll('.swiper-slide').length

  if (slideLength < 2) return

  bannerSlideBlock.classList.add('-active')

  new window.Swiper(bannerSlideElm, {
    speed: 500,
    autoplay: {
      delay: 3000
    },
    loop: true,
    pagination: {
      el: slideTargetClass + ' .swiper-pagination',
      clickable: true
    },
    navigation: {
      nextEl: slideTargetClass + ' .swiper-button-next',
      prevEl: slideTargetClass + ' .swiper-button-prev'
    }
  })
}

/* eslint-disable */
function initMail () {
  var button = document.querySelector('#js-moviebutton')
  var background = document.querySelector('#js-mailbackground')
  var wrapper = document.querySelector('#js-mailwrapper')
  var mail = document.querySelector('#js-mail')
  var top = document.querySelector('#js-mailtop')
  var shadow = document.querySelector('#js-mailtopshadow')
  var title = document.querySelector('#js-mailtitle')

  var buttonSP = document.querySelector('#js-moviebuttonSP')
  var backgroundSP = document.querySelector('#js-mailbackgroundSP')
  var wrapperSP = document.querySelector('#js-mailwrapperSP')
  var mailSP = document.querySelector('#js-mailSP')
  var topSP = document.querySelector('#js-mailtopSP')
  var shadowSP = document.querySelector('#js-mailtopshadowSP')
  var titleSP = document.querySelector('#js-mailtitleSP')

  var modal = document.querySelector('#js-modal')
  var close = document.querySelector('#js-modalclose')
  var close2 = document.querySelector('#js-modalclose2')
  var dummy = document.querySelector('#js-modalclosedummy')
  var content = document.querySelector('#js-modalcontent')

  addEvent()

  function addEvent () {
    button.addEventListener('mouseenter', onMouseenter)
    button.addEventListener('mouseleave', onMouseleave)
    button.addEventListener('click', onClick)

    buttonSP.addEventListener('mouseenter', onMouseenter)
    buttonSP.addEventListener('mouseleave', onMouseleave)
    buttonSP.addEventListener('click', onClick)
  }

  function addModalEvent () {
    close.addEventListener('click', closeModal)
    close2.addEventListener('click', closeModal)
    dummy.addEventListener('click', closeModal)
    content.addEventListener('click', cancel)
  }

  function removeEvent () {
    button.removeEventListener('mouseenter', onMouseenter)
    button.removeEventListener('mouseleave', onMouseleave)
    button.removeEventListener('click', onClick)

    buttonSP.removeEventListener('mouseenter', onMouseenter)
    buttonSP.removeEventListener('mouseleave', onMouseleave)
    buttonSP.removeEventListener('click', onClick)
  }

  function removeModalEvent () {
    close.removeEventListener('click', closeModal)
    close2.removeEventListener('click', closeModal)
    dummy.removeEventListener('click', closeModal)
    content.removeEventListener('click', cancel)
  }

  function cancel () {
    return false
  }


  function onMouseenter () {
    var target = getTarget()

    gsap.to(target.mail, {
      transform: 'translateZ(1px) rotateX(30deg) rotateZ(-3deg)',
      duration: 0.4,
      ease: 'power2.out'
    })

    gsap.to(target.top, {
      transform: 'rotateX(20deg)',
      duration: 0.4,
      ease: 'power2.out'
    })

    gsap.to(target.shadow, {
      opacity: 0.7,
      duration: 0.4,
      ease: 'power2.out'
    })

    gsap.to(target.title, {
      opacity: 0.7,
      duration: 0.4,
      ease: 'power2.out'
    })
  }

  function onMouseleave () {
    var target = getTarget()

    gsap.to(target.mail, {
      transform: 'translateZ(1px) rotateX(0deg) rotateZ(8deg)',
      duration: 0.4,
      ease: 'power2.out'
    })

    gsap.to(target.top, {
      transform: 'rotateX(0deg)',
      duration: 0.4,
      ease: 'power2.out'
    })

    gsap.to(target.shadow, {
      opacity: 1,
      duration: 0.4,
      ease: 'power2.out'
    })

    gsap.to(target.title, {
      opacity: 1,
      duration: 0.4,
      ease: 'power2.out'
    })
  }

  function onClick () {
    removeEvent()
    var target = getTarget()

    if (isPC()) {
      gsap.to(window, {
        scrollTo: 0,
        duration: 0.8,
        ease: 'power2.out'
      })
    }

    gsap.set(target.button, {
      zIndex: 9999
    })

    gsap.set(target.background, {
      display: 'block',
      opacity: 0
    })

    gsap.set(target.modal, {
      display: 'block',
      y: 50,
      opacity: 0
    })

    gsap.to(target.background, {
      opacity: 1,
      duration: 0.4
    })

    gsap.to(target.mail, {
      transform: 'translateZ(1px) rotateX(0deg) rotateZ(0deg) scale(1.8)',
      duration: 2,
      ease: 'power2.inOut'
    })

    gsap.to(target.top, {
      transform: 'rotateX(0deg)',
      duration: 0.4,
      ease: 'power2.out'
    })

    gsap.to(target.shadow, {
      opacity: 1,
      duration: 0.4,
      ease: 'power2.out'
    })

    gsap.to(target.title, {
      opacity: 1,
      duration: 0.4,
      ease: 'power2.out'
    })

    var positionX = isPC() ? window.innerWidth / 2 - 250 : window.innerWidth / 2 - 60
    var tl = gsap.timeline()

    tl.to(target.wrapper, {
      x: positionX,
      duration: 1,
      ease: 'power2.inOut'
    })
    tl.to(target.top, {
      transform: 'rotateX(180deg)',
      duration: 1,
      ease: 'power2.inOut',
      onStart: function () {
        var tl2 = gsap.timeline()
        tl2.to(target.shadow, {
          opacity: 0,
          duration: 0.5,
          ease: 'power2.in'
        })
        tl2.to(target.modal, {
          y: 0,
          opacity: 1,
          duration: 1,
          ease: 'power2.out',
          onComplete: function () {
            addModalEvent()
          }
        })
      }
    })
  }

  function closeModal () {
    removeModalEvent()
    var target = getTarget()

    gsap.to(target.mail, {
      transform: 'translateZ(1px) rotateX(0deg) rotateZ(8deg) scale(1)',
      duration: 2,
      ease: 'power2.inOut'
    })

    gsap.to(target.wrapper, {
      x: 0,
      duration: 1,
      delay: 0.5,
      ease: 'power2.inOut'
    })

    gsap.to(target.top, {
      transform: 'rotateX(0deg)',
      duration: 1,
      delay: 0.5,
      ease: 'power2.inOut'
    })

    gsap.to(target.shadow, {
      opacity: 1,
      duration: 0.5,
      delay: 1,
      ease: 'power2.out',
      onComplete: addEvent
    })

    gsap.to(target.background, {
      opacity: 0,
      duration: 1,
      ease: 'power2.inOut',
      onComplete: function () {
        gsap.set(background, {
          display: 'none'
        })
      }
    })

    gsap.to(target.modal, {
      y: 50,
      opacity: 0,
      duration: 0.7,
      ease: 'power2.in',
      onComplete: function () {
        gsap.set(target.modal, {
          display: 'none'
        })

        gsap.set(target.button, {
          zIndex: 999
        })
      }
    })
  }

  function getTarget () {
    return {
      button: isPC() ? button : buttonSP,
      background: isPC() ? background : backgroundSP,
      wrapper: isPC() ? wrapper : wrapperSP,
      mail: isPC() ? mail : mailSP,
      top: isPC() ? top : topSP,
      shadow: isPC() ? shadow : shadowSP,
      title: isPC() ? title : titleSP,
      modal: modal,
      close: close,
      close2: close2,
      dummy: dummy,
      content: content
    }
  }
}

function isPC () {
  return window.innerWidth > 768
}
